.education {
  width: 100%;
  margin-right: var(--space-6);
  margin-bottom: var(--space-6);
  margin-left: var(--space-6);
}

.education__title {
  margin-bottom: var(--space-4);
}

.education__info {
  display: grid;
  grid-row-gap: 1rem;
}

.education__details {
  display: grid;
  grid-template-columns: max-content;
}

.education__subtitle {
  color: var(--primary-400);
  font-weight: 600;
}

@media (min-width: $wt) {
  .education__info {
    grid-template-columns: $wt-container-left auto $wt-container-left;
    grid-column-gap: var(--space-6);
  }
}

@media (min-width: $wd) {
  .education {
    width: $wt-container-left;
    margin-bottom: var(--space-6);
    margin-right: 0;
  }

  .education__info {
    grid-template-columns: $wt-container-left;
  }
}

.about {
  margin-right: var(--space-6);
  margin-bottom: var(--space-6);
  margin-left: var(--space-6);
}

.about__photo {
  width: 8rem;
  height: 8rem;
  float: left;
  margin-right: var(--space-4);
  margin-bottom: var(--space-4);
}

.about__welcome {
  color: var(--primary-400);
  font-weight: 600;
}

.about__text {
  display: block;
  margin-top: var(--space-4);
}

.about__text--primary {
  color: var(--primary-500);
}

.about__text--through {
  color: var(--gray-500);
  text-decoration: line-through;
}

.about__text--success {
  color: var(--success-500);
  font-weight: 600;
}

.about__stack {
  @extend .grid;

  grid-template-columns: repeat(auto-fill, minmax($all-icon__size--x1h, 1fr));
  margin-top: var(--space-4);
}

.about__interests {
  @extend .grid;

  grid-template-columns: repeat(auto-fill, minmax($all-icon__size--x1h, 1fr));
  margin-top: var(--space-4);
}

@media (min-width: $wt) {
  .about {
    display: flex;
  }

  .about__photo {
    min-width: $wt-container-left;
    height: $ht-photo;
    float: none;
    margin-right: var(--space-6);
    margin-bottom: var(--space-6);
  }
}

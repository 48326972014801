.projects {
  margin-right: var(--space-6);
  margin-bottom: var(--space-6);
  margin-left: var(--space-6);
}

.projects__title {
  margin-bottom: var(--space-1);
}

.projects__list {
  @extend .lisn;
  margin: 0;
}

.projects__item {
  display: grid;
  grid-column-gap: var(--space-4);
  grid-row-gap: var(--space-4);
  grid-template-columns: repeat(2, 1fr);

  & span:nth-child(2) {
    justify-self: end;
  }

  & span:nth-child(3),
  & span:nth-child(4) {
    grid-column: 1 / 3;
  }
}

.projects__item:not(:last-child) {
  border-bottom: 1px solid var(--primary-200);
}

.projects__item--headings {
  display: none;
}

.projects__item--info {
  padding: var(--space-3) 0;
  font-size: $fs-sm;

  & span:nth-child(4) {
    color: var(--gray-500);
  }
}

.projects__item-link {
  @include link("primary");

  font-weight: 600;
  font-size: $fs-base;
}

.projects__item-type {
  padding: 0 var(--space-2);
  color: var(--gray-900);
  border-radius: var(--space-2);
}

.projects__item-type--commercial {
  background-color: var(--success-100);
}

.projects__item-type--private {
  background-color: var(--warning-100);
}

.projects__item-type--training {
  background-color: var(--danger-100);
}

@media (min-width: $wm-md) {
  .projects__title {
    margin-bottom: var(--space-4);
  }

  .projects__item--headings {
    display: grid;
    padding-bottom: var(--space-3);
    color: var(--primary-400);
    font-weight: 600;
  }

  .projects__item {
    grid-template-columns: 1fr 1fr 1fr 2fr 2fr;
    align-items: center;

    & span:nth-child(2) {
      justify-self: center;
      text-align: center;
    }

    & span:nth-child(3) {
      justify-self: center;
    }

    & span:nth-child(3) {
      grid-column: 3;
    }

    & span:nth-child(4) {
      grid-column: 4;
    }

    & span:nth-child(5) {
      grid-column: 5;
      text-align: right;
    }
  }

  .projects__item--info {
    & span:nth-child(4) {
      color: inherit;
    }
  }

  .projects__item-link {
    @include link("gray");

    font-weight: 400;
  }
}

@media (min-width: $wd) {
  .projects {
    margin-left: 0;
  }
}

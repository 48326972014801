@import "../../mixins/decorations";

.constacts {
  width: $wt-container-left;
  margin-right: var(--space-6);
  margin-bottom: var(--space-6);
  margin-left: var(--space-6);
}

.contact__title {
  margin-bottom: var(--space-4);
}

.contacts__list {
  @extend .lisn;

  margin: 0;
}

.contacts__item {
  margin-bottom: var(--space-4);
}


.contacts__item--additional {
  display: flex;
}

.contacts__item-link--main {
  @include link("gray");

  display: flex;
  align-items: center;
}


.contacts__item-link--additional {
  margin-right: var(--space-4);
}

.contacts__item-icon {
  margin-right: var(--space-4);
}

@media (min-width: $wd) {
  .constacts {
    margin-bottom: var(--space-6);
    margin-right: 0;
  }
}

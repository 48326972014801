@mixin lisn {
  list-style: none;
  padding-left: 0;
}

@mixin link($name) {
  color: var(--#{$name}-500);
  transition: var(--transition-time-base);

  &[href]:hover,
  &[href]:focus {
    color: var(--#{$name}-600);
    text-decoration: underline;
  }

  &[href]:active {
    color: var(--#{$name}-800);
  }
}

.technologies {
  margin-right: var(--space-6);
  margin-bottom: var(--space-6);
  margin-left: var(--space-6);
}

.technologies__title {
  margin-bottom: var(--space-4);
}

.technologies__block {
  @extend .grid;

  grid-template-columns: repeat(auto-fill, minmax($all-icon__size--x2h, 1fr));
}

@media (min-width: $wt) {
  .technologies {
    margin-bottom: 0;
  }
}

@media (min-width: $wd) {
  .technologies {
    width: $wt-container-left;
    margin-bottom: var(--space-6);
    margin-right: 0;
  }
}

.professional-experience {
  margin-right: var(--space-6);
  margin-bottom: var(--space-6);
  margin-left: var(--space-6);
}

.professional-experience__title {
  margin-bottom: var(--space-4);
}

.professional-experience__branches {
  position: relative;
  padding-right: var(--space-2);
}

.professional-experience__branches::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  left: auto;
  width: $all-timeline-tree;
  height: 100%;
  background: var(--primary-300);
}

.professional-experience__block--left,
.professional-experience__block--right {
  margin-bottom: var(--space-4);
  text-align: right;
}

.professional-experience__block--left {
  min-height: $wm-experience-block;
}

.professional-experience__block--right:not(:last-child) {
  min-height: $wm-experience-block;
}

.professional-experience__info {
  display: flex;
  flex-direction: column;
  margin-top: var(--space-3);
}

.professional-experience__info--left,
.professional-experience__info--right {
  padding-right: var(--space-4);
}

.professional-experience__period {
  color: var(--primary-400);
  font-weight: 600;
  border-bottom: $all-timeline-branch solid var(--primary-300);
}

.professional-experience__period--left,
.professional-experience__period--right {
  padding-right: var(--space-4);
}

.professional-experience__position {
  color: var(--gray-500);
}

@media (min-width: $wm-md) {
  .professional-experience__branches {
    display: grid;
    grid-column-gap: $all-timeline-tree;
    grid-template-columns: 50% 50%;
    padding-right: 0;
  }

  .professional-experience__branches::after {
    right: 50%;
    left: 50%;
  }

  .professional-experience__block--left:not(:last-child) {
    margin-bottom: $wm-md-experience-block;
  }

  .professional-experience__block--right {
    text-align: left;
    margin-top: $wm-md-experience-block;
    margin-bottom: 0;
  }

  .professional-experience__info {
    display: flex;
    flex-direction: column;
    margin-top: var(--space-3);
  }

  .professional-experience__info--left {
    padding-right: var(--space-4);
  }

  .professional-experience__info--right {
    padding-left: var(--space-4);
    padding-right: 0;
  }

  .professional-experience__period--left {
    padding-right: var(--space-4);
  }

  .professional-experience__period--right {
    padding-left: var(--space-4);
    padding-right: 0;
  }
}

@media (min-width: $wd) {
  .professional-experience {
    margin-left: 0;
  }
}

.toggle {
  @extend .tap-none;

  position: relative;
  display: block;
  width: 34px;
  height: 14px;
  border-radius: 7px;
  cursor: pointer;
}

.toggle--on,
.toggle--on:focus,
.toggle--off,
.toggle--off:focus {
  background: var(--gray-100);
}

.toggle::before {
  content: '';
  position: absolute;
  top: -3px;

  display: block;
  width: 20px;
  height: 20px;

  border: 1px solid transparent;
  border-radius: 50%;

  transition: right ease var(--transition-time-base);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.5);
}

.toggle--on::before {
  right: 0;
  background-color: var(--primary-500);
}

.toggle--off::before {
  right: calc(34px - 20px);
  background-color: var(--gray-500);
}

.toggle--type::before {
  border-color: $white;
  background-color: $white;
}

.toggle--on-language::before {
  background-image: url(../img/icons/icon-flag-ru.svg);
}

.toggle--off-language::before {
  background-image: url(../img/icons/icon-flag-en.svg);
}

.toggle--on-theme::before {
  background-image: url(../img/icons/icon-theme-light.svg);
}

.toggle--off-theme::before {
  background-image: url(../img/icons/icon-theme-dark.svg);
}

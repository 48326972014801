@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/OpenSans-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/OpenSans-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/OpenSans-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/OpenSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/OpenSans-Bold.woff2') format('woff2');
}

$module: 8px;

$wm: 320px;
$wm-md: 640px;
$wt: 768px;
$wd: 1280px;

$wm-photo: 8rem;
$wm-experience-block: 6rem;
$wm-md-experience-block: 5rem;

$wt-certificates__resourse-title: 8.5rem;
$wt-container-left: 16rem;
$ht-photo: $wt-container-left;

$all-timeline-tree: 8px;
$all-timeline-branch: 4px;
$all-icon__size--x1: 1rem;
$all-icon__size--x1h: 1.5rem;
$all-icon__size--x2h: 2.5rem;

$font-main: "Open Sans", "Arial", sans-serif;

$fs-base: 0.875rem;
$fs-sm: 0.75rem;

$transition-time-base: 300ms;

.content {
  position: relative;
  min-width: $wm;
  max-width: $wt;
  margin: var(--space-6) auto;
  padding: var(--space-6) 0;
  box-shadow:
    0 8px 16px rgba(110, 120, 130, 0.12),
    0 -8px 16px rgba(110, 120, 130, 0.12);
  background: var(--light);
}

.content__info {
  display: flex;
  flex-direction: column;
  height: calc(100vh - calc((var(--space-6) * 4)));
  overflow-x: hidden;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: $wt) {
  .content {
    max-width: $wd;
  }

  .content__details {
    display: flex;
    flex-direction: column;
  }

  .content__details--left {
    display: grid;
    grid-column-gap: var(--space-6);
    grid-template-columns: $wt-container-left auto;
  }

  .content__education {
    grid-column: 1/2;
  }
}

@media (min-width: $wd) {
  .content {
    max-width: $wd;
  }

  .content__details {
    flex-direction: row;
  }

  .content__details--left {
    display: block;
  }

  .content__details--right {
    margin-left: var(--space-6);
  }
}

.switches {
  display: flex;
  position: fixed;
  top: var(--space-2);
  right: var(--space-4);
}

.switches__theme {
  margin-right: var(--space-5);
}

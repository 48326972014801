@import "../../mixins/decorations";

.certificates {
  margin-right: var(--space-6);
  margin-left: var(--space-6);
}

.certificates__title {
  margin-bottom: var(--space-4);
}

.certificates__list {
  @extend .lisn;

  display: grid;
  grid-row-gap: var(--space-4);
  margin: 0;
}

.certificates__resourse {
  display: grid;
  grid-row-gap: var(--space-2);
}

.certificates__resourse:not(:last-child) {
  padding-bottom: var(--space-4);
  border-bottom: 1px solid var(--primary-200);
}

.certificates__resourse-title {
  font-weight: 600;
  color: var(--primary-400);
}

.certificates__resourse-list {
  @extend .lisn;
}

.certificates__year {
  display: grid;
  grid-column-gap: var(--space-4);
  grid-template-columns: min-content auto;
}

.certificates__year-list {
  @extend .lisn;

  display: grid;
  grid-row-gap: var(--space-2);
  margin-top: var(--space-5);
}

.certificates__course {
  @include link("gray");

  font-size: $fs-sm;
}

@media (min-width: $wt) {
  .certificates__resourse {
    grid-template-columns: $wt-certificates__resourse-title auto;
  }

  .certificates__resourse-list {
    margin-top: var(--space-5);
  }
}

@media (min-width: $wd) {
  .certificates {
    margin-left: 0;
  }

  .certificates__resourse:not(:last-child) {
    border-bottom: none;
  }
}
